import '../../css/home.css';

window.addEventListener('DOMContentLoaded', function () {
    
    let photoIndex = 1,//start at 1 because I prerender the 0th
    needToLoadNext = true,
    intervalId = undefined,
    changeTime = 5 * 1000,
    currentPhoto = document.getElementById('currentPhoto'),
    nextPhoto = document.getElementById('nextPhoto')

    function changeBackground() {
        currentPhoto.style.backgroundImage = 'url(' + photos[photoIndex++] + ')';
        if (photoIndex >= 10) {
            photoIndex = 0;
            needToLoadNext = false;
            if (!intervalId) {
                intervalId = setInterval(changeBackground, changeTime);
            }
        }
        if (needToLoadNext) {
            nextPhoto.src = photos[photoIndex];
        }
    }

    nextPhoto.addEventListener('load', function () {
        setTimeout(changeBackground, changeTime);
    });
    setTimeout(changeBackground, changeTime);
});